import axios from "axios";

const BASE_URL = "https://stmarcinaschool.com/api/api/";

const ApiService = axios.create({
  baseURL: BASE_URL,
});

export const fetchgallery = async () => {
  try {
    const response = await ApiService.get("gallery/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const response = await ApiService.post("contact/", formData);
    return response.data;
  } catch (error) {
    console.error("Error during contact form submission", error);
    throw error;
  }
};
export const fetchnewsevent = async () => {
  try {
    const response = await ApiService.get("events/");
    return response.data;
  } catch (error) {
    console.error("Error during Category fetch", error);
    throw error;
  }
};
