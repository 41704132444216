import React, { useState, useEffect } from "react";
import { fetchgallery } from "./Apiservice"; // Keep your existing fetch function
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";

const fetchImagesByYearAndMonth = async (year, month) => {
  const response = await fetch(
    `https://stmarcinaschool.com/api/api/gallery/?year_id=${year}&month=${month}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch images");
  }
  return await response.json();
};

const fetchYears = async () => {
  const response = await fetch("https://stmarcinaschool.com/api/api/year/");
  if (!response.ok) {
    throw new Error("Failed to fetch years");
  }
  return await response.json();
};

const Gallery = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesByYear, setImagesByYear] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredImages, setFilteredImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [yearInput, setYearInput] = useState("");
  const [monthInput, setMonthInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const [years, setYears] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await fetchgallery();
        setImagesByYear(galleryData);
        setFilteredImages(galleryData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    const fetchYearData = async () => {
      try {
        const yearData = await fetchYears();
        setYears(yearData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchYearData();
    fetchData();
  }, []);

  const handleYearMonthSearch = async () => {
    if (!yearInput) {
      alert("Please select a year.");
      return;
    }

    try {
      const images = await fetchImagesByYearAndMonth(yearInput, monthInput);
      const filtered = {};

      if (monthInput === "All") {
        // Store all images for the selected year
        filtered[yearInput] = images[yearInput] || []; // Adjust based on your API response structure
      } else {
        // Filter images by the selected month
        filtered[yearInput] =
          images[yearInput]?.filter(
            (image) => image.month.toLowerCase() === monthInput.toLowerCase()
          ) || [];
      }

      setImagesByYear(filtered);
      setFilteredImages(filtered);
      setCurrentPage(1); // Reset to the first page
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    const filtered = {};
    Object.keys(imagesByYear).forEach((year) => {
      const imagesInYear = imagesByYear[year].filter((image) => {
        const matchesName = image.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesYear = year.includes(searchTerm);
        return matchesName || matchesYear;
      });

      if (imagesInYear.length > 0) {
        filtered[year] = imagesInYear;
      }
    });
    setFilteredImages(filtered);
    setCurrentPage(1);
  }, [searchTerm, imagesByYear]);

  const openModal = (year, indexInYear) => {
    const flatImages = Object.values(filteredImages).flat();
    const yearIndex = Object.keys(filteredImages).indexOf(year);
    const globalIndex =
      Object.values(filteredImages).slice(0, yearIndex).flat().length +
      indexInYear;
    setCurrentIndex(globalIndex);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const prevImage = () => {
    setCurrentIndex(
      currentIndex > 0
        ? currentIndex - 1
        : Object.values(filteredImages).flat().length - 1
    );
  };

  const nextImage = () => {
    setCurrentIndex(
      currentIndex < Object.values(filteredImages).flat().length - 1
        ? currentIndex + 1
        : 0
    );
  };

  const sortedYears = Object.keys(filteredImages).sort((a, b) => b - a);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedYears = sortedYears.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const totalPages = Math.ceil(sortedYears.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading gallery: {error.message}</div>;

  return (
    <div>
      <div className="page-title" data-aos="fade">
        <div className="heading" id="Gallery-banner">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              {/* Gallery Title */}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container mt-3 d-flex justify-content-end col-12">
          <div className="d-flex align-items-center">
            <select
              className="form-select me-2 "
              value={yearInput}
              onChange={(e) => setYearInput(e.target.value)}
              aria-label="Select year"
            >
              <option value="" disabled>
                Select year...
              </option>
              {years.map((yearObj) => (
                <option key={yearObj.year} value={yearObj.year}>
                  {yearObj.year}
                </option>
              ))}
            </select>

            <select
              className="form-select me-2"
              value={monthInput}
              onChange={(e) => setMonthInput(e.target.value)}
              aria-label="Select month"
            >
              <option value="" disabled>
                Select month...
              </option>
              <option value="All">All</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>

            <button onClick={handleYearMonthSearch} className="btn btn-primary">
              Search
            </button>
          </div>
        </div>

        {/* Year-wise image display */}
        {paginatedYears.length > 0 ? (
          paginatedYears.map((year) => (
            <div key={year} className="year-section mt-2 mb-5 container">
              <h2>{year}</h2>
              <div className="gallery-grid">
                {filteredImages[year] && filteredImages[year].length > 0 ? (
                  filteredImages[year].map((image, index) => (
                    <div
                      className="gallery-item"
                      key={image.id}
                      onClick={() => openModal(year, index)}
                    >
                      <img src={image.image} alt={image.name} />
                      <div className="overlay">
                        <span>
                          {image.month} {image.date}
                        </span>
                      </div>
                      <p className="gallery-item-text">{image.name}</p>
                    </div>
                  ))
                ) : (
                  <div>No images found for {year}</div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>No images found</div>
        )}

        {/* Pagination Controls */}
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            <HiArrowNarrowLeft />
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            <HiArrowNarrowRight />
          </button>
        </div>

        {/* Modal */}
        {modalOpen && (
          <div id="modal" className="modal responsive-modal">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-content">
              <div className="modal-left">
                <img
                  id="modal-img"
                  src={Object.values(filteredImages).flat()[currentIndex].image}
                  alt={Object.values(filteredImages).flat()[currentIndex].name}
                />
                <p
                  id="modal-text"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  {Object.values(filteredImages).flat()[currentIndex].name} -
                  {Object.values(filteredImages).flat()[currentIndex].year}
                </p>
              </div>
              <div className="modal-right">
                <div className="nav-buttons">
                  <button
                    className="nav-btn"
                    id="nav-btn-left"
                    onClick={prevImage}
                  >
                    &#9664;
                  </button>
                  <button
                    className="nav-btn"
                    id="nav-btn-right"
                    onClick={nextImage}
                  >
                    &#9654;
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
